import React from "react";

const CallToAction = () => {
    return (
        <section className="py-20 relative overflow-hidden" style={{
            background: `linear-gradient(135deg, #2196F3 0%, #1976D2 100%)`
        }}>
            {/* Background decorative elements */}
            <div className="absolute inset-0 overflow-hidden">
                <div className="absolute w-full h-full" style={{
                    background: `radial-gradient(circle at 0% 0%, rgba(255,255,255,0.1) 0%, transparent 50%),
                                radial-gradient(circle at 100% 100%, rgba(255,255,255,0.1) 0%, transparent 50%)`
                }}></div>
                <div
                    className="absolute inset-0 opacity-10"
                    style={{
                        backgroundImage: `url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='white' fill-opacity='1' fill-rule='evenodd'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/svg%3E")`
                    }}
                ></div>
            </div>

            <div className="container mx-auto px-4 relative">
                <div className="max-w-4xl mx-auto text-center">
                    <div
                        className="inline-flex items-center bg-white/10 backdrop-blur-sm px-6 py-2 rounded-full gap-2 mb-8">
                        <span className="w-2 h-2 bg-white rounded-full animate-pulse"></span>
                        <span className="text-white/90 text-sm font-medium">Crescimento Comprovado</span>
                    </div>

                    <h2 className="text-center text-3xl md:text-4xl font-bold text-white mb-6">
                        <div className="flex flex-col items-center gap-2">
                            <span>AUMENTE SEU</span>
                            <div className="flex flex-wrap justify-center gap-2">
                                <span className="bg-white text-[#2196F3] px-6 py-2 rounded-lg">FATURAMENTO EM 3X</span>
                            </div>
                            <span className="text-2xl md:text-3xl mt-2">COM O SISTEMA #1 DO MERCADO</span>
                        </div>
                    </h2>

                    <p className="text-white/90 text-xl mb-12 max-w-2xl mx-auto">
                        Mais de 560 oficinas já conquistaram +15 mil clientes usando nossa plataforma
                    </p>

                    <div className="flex flex-col sm:flex-row gap-4 justify-center mb-16">
                        <button
                            className="group px-8 py-4 bg-white hover:bg-blue-50 text-[#2196F3] rounded-lg
                                     font-bold text-lg transition-all duration-300 hover:-translate-y-1
                                     shadow-lg hover:shadow-xl flex flex-col items-center"
                            onClick={() => window.open('https://sistema.garagehero.com.br/registrar', '_blank')}
                            id="cta-footer-button-start"
                        >
                            <span>Comece Agora Mesmo</span>
                            <span className="text-sm font-normal text-[#2196F3]/80">Teste Grátis por 14 dias</span>
                        </button>

                        <button
                            className="group px-8 py-4 bg-transparent hover:bg-white/10 text-white
                                     border-2 border-white/80 hover:border-white rounded-lg
                                     font-bold text-lg transition-all duration-300 hover:-translate-y-1
                                     flex flex-col items-center backdrop-blur-sm"
                            onClick={() => window.open('https://wa.me/5511934099298?text=Ol%C3%A1%2C%20estou%20no%20site%20do%20GarageHero%20e%20desejo%20ajuda', '_blank')}
                            id="cta-footer-button-demo"
                        >
                            <span>Ver Demonstração</span>
                            <span className="text-sm font-normal text-white/80">Atendimento Personalizado</span>
                        </button>
                    </div>

                    <div className="grid grid-cols-2 md:grid-cols-4 gap-4 md:gap-6 max-w-3xl mx-auto">
                        {[
                            {value: '560+', label: 'Oficinas Ativas'},
                            {value: '66.6k+', label: 'Ordens de Serviço'},
                            {value: 'R$1.3k+', label: 'Ticket Médio'},
                            {value: '98%', label: 'Satisfação'},
                        ].map((stat, index) => (
                            <div key={index}
                                 className="bg-white/10 hover:bg-white/15 rounded-lg p-6 backdrop-blur-sm
                                          transition-all duration-300 hover:-translate-y-1">
                                <div className="text-3xl font-bold text-white mb-1">{stat.value}</div>
                                <div className="text-white/80 text-sm">{stat.label}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CallToAction;