import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import HeaderCarWash from './components/CarWash/Header';
import HeroSection from './components/HeroSection';
import CarouselSection from './components/CarouselSection';
import HighlightsSection from './components/HighlightsSection';
import CompaniesProfileSection from './components/CompaniesProfileSection';
import TestimonialsSection from './components/TestimonialsSection';
import BenefitsSection from './components/BenefitsSection';
import PricingSection from './components/PricingSection';
import BusinessPlansSection from './components/BusinessPlansSection';
import NewsSection from './components/NewsSection';
import RepresentativeSection from './components/RepresentativeSection';
import SupportSection from './components/SupportSection';
import Footer from './components/Footer';
import Separator from './components/Separetor';
import FlashComponent from './components/FlashComponent';
import PricingPage from './pages/PricingPage';
import HelpCenter from './pages/HelpCenter';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfUse from './pages/TermsOfUse';
import ContactPage from './pages/ContactPage';
import { Consent, FloatingWhatsAppButton } from './components/Consent';
import ArticlePage from './pages/ArticlePage';
import CarWashLandingPage from './pages/CarWashLandingPage';

const isCarWashSubdomain = () => {
  return window.location.hostname.startsWith('lava-rapido.');
};

const getMainDomain = () => {
  const hostname = window.location.hostname;
  return hostname.substring(hostname.indexOf('.') + 1);
};

const RedirectToMainDomain = ({ path }) => {
  const mainDomain = getMainDomain();
  const protocol = window.location.protocol;
  const fullPath = `${protocol}//${mainDomain}${path}`;
  window.location.href = fullPath;
  return null;
};

function App() {
  const [cookiesAccepted, setCookiesAccepted] = useState(false);

  const handleAcceptCookies = () => {
    setCookiesAccepted(true);
  };

  if (isCarWashSubdomain()) {
    return (
        <Router>
          <div className="flex flex-col min-h-screen">
            <HeaderCarWash />
            <FlashComponent />
            <main className="flex-grow">
              <Routes>
                <Route path="/" element={<CarWashLandingPage />} />
                <Route
                    path="*"
                    element={
                      <RedirectToMainDomain path={window.location.pathname} />
                    }
                />
              </Routes>
            </main>
            <Footer />
            <FloatingWhatsAppButton cookiesAccepted={cookiesAccepted} />
            <Consent onAccept={handleAcceptCookies} />
          </div>
        </Router>
    );
  }

  return (
      <Router>
        <div className="flex flex-col min-h-screen">
          <Header />
          <FlashComponent />
          <main className="flex-grow">
            <Routes>
              <Route path="/" element={
                <>
                  <HeroSection />
                  <CarouselSection />
                  <Separator />
                  <HighlightsSection />
                  <CompaniesProfileSection />
                  <TestimonialsSection />
                  <BenefitsSection />
                  <Separator />
                  <PricingSection />
                  <Separator />
                  <BusinessPlansSection />
                  <NewsSection />
                  <RepresentativeSection />
                  <SupportSection />
                </>
              } />
              <Route path="/funcionalidades" element={<PricingPage />} />
              <Route path="/central-de-ajuda" element={<HelpCenter />} />
              <Route path="/central-de-ajuda/artigo/:id" element={<ArticlePage />} />
              <Route path="/politica-de-privacidade" element={<PrivacyPolicy />} />
              <Route path="/termos-de-uso" element={<TermsOfUse />} />
              <Route path="/contato" element={<ContactPage />} />
            </Routes>
          </main>
          <Footer />
          <FloatingWhatsAppButton cookiesAccepted={cookiesAccepted} />
          <Consent onAccept={handleAcceptCookies} />
        </div>
      </Router>
  );
}

export default App;